<template>
  <div>
    <div>
      <div v-if="calc_passing_date != null">
        <span class="text-wrap badge badge-light text-danger csfs2 d-block">
          {{ calc_passing_date.due_date }}
        <span
          v-if="calc_passing_date.passing_date > 0"
          class="text-xs d-block"
        >
          {{ calc_passing_date.passing_date }} Gün Geçti</span
        >
        </span>

      </div>
      <status-badge
        v-if="get_device_status"
        classlist="d-block text-uppercase text-wrap csmt5"
        :status="get_device_status"
      ></status-badge>
    </div>
    <div
      class="text-wrap csmt5"
      v-if="arg.event.extendedProps.customer_title != ''"
    >
      {{ arg.event.extendedProps.customer_title }}
    </div>
    <div v-if="arg.event.extendedProps.device_name != ''" class="text-sm">
      <div class="text-wrap badge text-center d-block">
        {{ arg.event.extendedProps.device_name }}
      </div>
    </div>
    <div
      class="text-wrap csmt5"
      v-if="arg.event.extendedProps.district_name != null"
    >
      <feather-icon icon="MapPinIcon" />
      {{ arg.event.extendedProps.district_name }}
    </div>
  </div>
</template>

<script>
import StatusBadge from "../common/StatusBadge.vue";
import { mapState, mapGetters, mapActions, mapMutations } from "vuex";
import utils from "@/service/utils";

export default {
  components: {
    StatusBadge,
  },
  props: ["arg"],
  computed: {
    ...mapState({
      sf: (state) => state.sf,
      calendar: (state) => state.calendar,
      global: (state) => state.global,
    }),
    get_device_status() {
      let result = null;
      if (this.arg.event.extendedProps.device_item != null) {
        let d = {
          status: this.arg.event.extendedProps.device_item.status,
          status_text:
            this.arg.event.extendedProps.device_item.status.action_status,
        };
        result = d;
      }
      return result;
    },
    calc_passing_date() {
      let result = null;
      let device_item = this.arg.event.extendedProps.device_item;
      if (device_item != null && device_item.due_date != null) {
        result = {
          due_date: utils.moment(device_item.due_date).format("DD.MM.YYYY"),
          passing_date: null,
        };
        let diff = utils
          .moment()
          .diff(
            utils.moment(this.arg.event.extendedProps.device_item.due_date),
            "day"
          );
        result.passing_date = diff;
      }
      return result;
    },
  },
  created() {
    console.log("AAA", this.arg.event.extendedProps);
  },
  methods: {
    getStatus(extendedProps) {
      return {
        status: extendedProps.task_status,
        status_text: extendedProps.task_status_text,
      };
    },
  },
};
</script>
<style>
</style>