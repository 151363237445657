<template>
  <div>
    <b-modal
      id="mymodalevent"
      ref="modalevent"
      @hidden="hideModal"
      size="lg"
      hide-header
      hide-footer
    >
      <b-tabs>
        <b-tab v-if="calendar.event !=null " title="Operasyon Görev Bilgileri">
          <div v-if="calendar.event.sf_item != null" class="border-bottom pb-05 pt-05">
            <label class="font-medium text-uppercase text-primary">Servis Bilgileri</label>
            <div class="row">
              <div class="col-sm-4">
                <label class="font-medium text-uppercase">Servis No</label>
              </div>
              <div class="col-sm-8">
                <router-link
                  :to="{ name: 'service', query: { uniq: calendar.event.sf_item.sf_uniq },}">
                  #{{ calendar.event.sf_item.sf_id }}
                </router-link>
                <span class="ml-1"
                  >Tarihi:
                  {{
                    moment(calendar.event.sf_item.sf_date).format("DD.MM.YYYY")
                  }}</span
                >
              </div>
            </div>
            <div class="row">
              <div class="col-sm-4">
                <label class="font-medium text-uppercase">Müşteri SMS Bilgilendirmeleri</label>
              </div>
              <div class="col-sm-8">
                  <span v-if="calendar.event.sf_item.config_send_notification == 1" class="badge badge-success"></span>
                  <span v-if="calendar.event.sf_item.config_send_notification == 0" class="badge badge-warning">Kapalı</span>
              </div>
            </div>
          </div>
          <div
            v-if="calendar.event.customer_item != null"
            class="border-bottom pb-05 pt-05"
          >
            <label class="font-medium text-uppercase text-primary"
              >Müşteri Bilgileri</label
            >
            <div class="row">
              <span class="col-sm-4">Ad Soyad</span>
              <span class="col-sm-8"
                >{{ calendar.event.customer_item.title }} #{{
                  calendar.event.customer_item.customer_code
                }}</span
              >
            </div>
            <div class="row">
              <span class="col-sm-4">GSM</span>
              <span class="col-sm-8"
                ><a :href="'tel:' + calendar.event.customer_item.gsm_primary">{{
                  calendar.event.customer_item.gsm_primary
                }}</a></span
              >
            </div>
            <div class="row">
              <span class="col-sm-4">Email</span>
              <span class="col-sm-8">{{
                calendar.event.customer_item.email_primary
              }}</span>
            </div>
          </div>
          <div class="row border-bottom pb-05 pt-05">
            <div class="col-sm-4">
              <label class="font-medium text-uppercase">Görev Tanımı</label>
            </div>
            <div class="col-sm-8">
              <span v-if="calendar.event.task != null">
                <span class="badge badge-secondary">{{
                  calendar.event.task.priority_text
                }}</span>
                #{{ calendar.event.task.task_id }}
              </span>
            </div>
          </div>
          <div class="row border-bottom pb-05 pt-05">
            <div class="col-sm-4">
              <label class="font-medium text-uppercase">Açıklamalar</label>
            </div>
            <div class="col-sm-8">
              <span v-if="calendar.event.task != null">
                {{ calendar.event.task.task_notes }}</span
              >
            </div>
          </div>
          <div class="row border-bottom pb-05 pt-05">
            <div class="col-sm-4">
              <label class="font-medium text-uppercase">Görev Durumu</label>
            </div>
            <div class="col-sm-8">
              <status-badge :status="calendar.event" />
            </div>
            <div class="col-sm-4">
              <label class="font-medium text-uppercase">İlgili Personel</label>
            </div>
            <div class="col-sm-8">
              <span v-if="calendar.event.task != null && calendar.event.task.staff_item !=null ">
                {{ calendar.event.task.staff_item.user_namesurname }}
              </span>
              <span v-else>Belirtilmedi</span>
            </div>
            <div class="col-sm-4">
              <label class="font-medium text-uppercase">Tamamlayan Personel</label>
            </div>
            <div class="col-sm-8">
              <span v-if="calendar.event.task != null && calendar.event.task.complated_statt_item !=null ">
                {{ calendar.event.task.complated_statt_item.user_namesurname }}
              </span>
              <span v-else>Belirtilmedi</span>
            </div>
          </div>
          <div v-if="calendar.event.delivery_item != null">
            <div class="row border-bottom pb-05 pt-05">
              <div class="col-sm-4">
                <label class="font-medium text-uppercase">Adres</label>
              </div>
              <div class="col-sm-8">
                <div>
                  {{
                    calendar.event.delivery_item.district_text +
                    " " +
                    calendar.event.delivery_item.city_text
                  }}
                </div>
                <div>{{ calendar.event.delivery_item.delivery_address }}</div>
              </div>
            </div>
            <div class="row border-bottom pb-05 pt-05">
              <div class="col-sm-4">
                <label class="font-medium text-uppercase"
                  >Adreste Olması Gereken Tarih</label
                >
              </div>
              <div class="col-sm-8">
                {{
                  moment(
                    calendar.event.delivery_item.date_tobe_at_address
                  ).format("DD.MM.YYYY HH:mm")
                }}
              </div>
            </div>
            <div class="row border-bottom pb-05 pt-05">
              <div class="col-sm-4">
                <label class="font-medium text-uppercase">Konum</label>
              </div>
              <div class="col-sm-8">
                <span v-if="calendar.event.delivery_item.location == ''"
                  >Konum Bilgisi Bulunmuyor</span
                >
                <a
                  v-else
                  class="btn btn-sm btn-danger"
                  :href="calendar.event.delivery_item.location"
                  target="_blank"
                  >Haritada Aç</a
                >
              </div>
            </div>
            <div v-if="calendar.event.delivery_item!=null">
            <div v-if="calendar.event.delivery_item.payment_delivery_item!=null " class="row border-bottom pb-05 pt-05">
              <div class="col-sm-4">
                <label class="font-medium text-uppercase">Teslimat Ücreti</label>
              </div>
              <div class="col-sm-8">
                {{ calendar.event.delivery_item.payment_delivery_item.amount }}
                {{ calendar.event.delivery_item.payment_delivery_item.currency_text }}
              </div>
            </div>
            <div v-if="calendar.event.delivery_item.payment_service_item!=null && calendar.event.delivery_item.payment_service_item.show_user == 1" class="row border-bottom pb-05 pt-05">
              <div class="col-sm-4">
                <label class="font-medium text-uppercase">Servis Ücreti</label>
              </div>
              <div class="col-sm-8">
                {{ calendar.event.delivery_item.payment_service_item.amount }}
                {{ calendar.event.delivery_item.payment_service_item.currency_text }}
              </div>
            </div>
            </div>  
          </div>
        </b-tab>
      </b-tabs>
      <div class="modal-footer justify-content-start pl-0 ml-n1 border-0">
        <button v-if="calendar.event.task!=null && calendar.event.task.status == 'pending'" @click="startTask()" class="btn btn-primary">Görevi Başlat</button>
        <button v-if="calendar.event.task!=null && calendar.event.task.status == 'pending'" @click="startTask()" class="btn btn-primary">Görevi Başlat</button>
      </div>

      <div v-if="false">
        <b-tabs content-class="pt- pb-2 ">
          <b-tab
            v-if="calendar.tabs.task.visible == true"
            title="Görev Bilgileri"
            :disabled="calendar.tabs.task.disabled"
          >
            <div>
              <div class="row">
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="font-medium text-uppercase"
                      >Görev Tanımı</label
                    >
                    <v-select
                      v-model="sf.task.task_definition_id"
                      :searchable="false"
                      :clearable="false"
                      :reduce="(curr) => curr.value"
                      :options="global.task_todo"
                    />
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="font-medium text-uppercase"
                      >Öncelik Derecesi</label
                    >
                    <v-select
                      v-model="sf.task.priority_id"
                      :searchable="false"
                      :clearable="true"
                      :options="global.delivery_priority"
                      :reduce="(curr) => curr.value"
                    />
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label class="font-medium text-uppercase"
                  >Tanım Açıklaması</label
                >
                <textarea
                  v-model="sf.task.task_notes"
                  class="form-control"
                  rows="2"
                ></textarea>
              </div>
              <div class="row">
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="font-medium text-uppercase"
                      >İlgili Personel</label
                    >
                    <v-select
                      :searchable="true"
                      :clearable="true"
                      v-model="sf.task.staff_id"
                      :reduce="(curr) => curr.value"
                      :options="global.users"
                    />
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="font-medium text-uppercase"
                      >Planlanan Görev Tarihi</label
                    >
                    <flat-pickr
                      class="form-control"
                      v-model="sf.task.planned_date"
                      :config="{
                        enableTime: true,
                        altFormat: 'd.m.Y H:i',
                        altInput: true,
                        dateFormat: 'Y-m-d H:i',
                        locale: 'tr',
                      }"
                    />
                  </div>
                </div>
              </div>
              <div
                v-if="sf.delivery.d_id <= 0 && sf.task.task_type != 'task'"
                class="form-group d-flex"
              >
                <b-form-checkbox
                  @change="triggerChangeCalendarTask"
                  v-model="calendar.is_delivery_task"
                  id="cb_delivery_task"
                />
                <label class="mb-0" for="cb_delivery_task"
                  >TESLİMAT OLUŞTUR</label
                >
              </div>
            </div>
          </b-tab>
          <b-tab
            v-if="calendar.tabs.delivery.visible == true"
            title="Teslimat Bilgileri"
            :disabled="calendar.tabs.delivery.disabled"
          >
            <b-row>
              <b-col md="6" lg="6">
                <div class="form-group">
                  <label class="font-medium text-uppercase">İlçe</label>
                  <v-select
                    :searchable="true"
                    :clearable="true"
                    :options="districts"
                    :reduce="(curr) => curr.district_id"
                    v-model="sf.delivery.district_id"
                  />
                </div>
              </b-col>
              <b-col md="6" lg="6">
                <div class="form-group">
                  <label class="font-medium text-uppercase">Konum</label>
                  <b-input-group>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Harita konumu"
                      v-model="sf.delivery.location"
                    />
                    <b-input-group-append>
                      <b-button variant="outline-secondary"> Aç </b-button>
                    </b-input-group-append>
                  </b-input-group>
                </div>
              </b-col>
              <b-col md="6" lg="6">
                <div class="form-group">
                  <label class="font-medium text-uppercase"
                    >Teslimat Ücreti</label
                  >
                  <b-input-group>
                    <b-form-input
                      v-model="sf.delivery.fee_delivery"
                    ></b-form-input>
                    <template #append>
                      <b-dropdown
                        :text="
                          getDefinitionName(sf.delivery.fee_delivery_curreny_id)
                        "
                        variant="outline-secondary"
                      >
                        <b-dropdown-item
                          v-on:click="
                            sf.delivery.fee_delivery_curreny_id =
                              item.definition_id
                          "
                          v-for="item in global.currencies"
                          :key="item.definition_id"
                          >{{ item.label }}</b-dropdown-item
                        >
                      </b-dropdown>
                    </template>
                  </b-input-group>
                </div>
              </b-col>
              <b-col md="6" lg="6">
                <div class="form-group">
                  <label class="font-medium text-uppercase"
                    >Hizmet Ücreti</label
                  >
                  <b-input-group>
                    <b-form-input
                      v-model="sf.delivery.fee_service"
                    ></b-form-input>
                    <template #append>
                      <b-dropdown
                        :text="
                          getDefinitionName(sf.delivery.fee_service_curreny_id)
                        "
                        variant="outline-secondary"
                      >
                        <b-dropdown-item
                          v-on:click="
                            sf.delivery.fee_service_curreny_id =
                              item.definition_id
                          "
                          v-for="item in global.currencies"
                          :key="item.definition_id"
                          >{{ item.label }}</b-dropdown-item
                        >
                      </b-dropdown>
                    </template>
                  </b-input-group>
                </div>
              </b-col>
            </b-row>
            <div class="form-group">
              <label class="font-medium text-uppercase">Açık Adres</label>
              <textarea
                v-model="sf.delivery.delivery_address"
                class="form-control"
                rows="3"
              ></textarea>
            </div>
          </b-tab>
          <b-tab
            v-if="calendar.tabs.customer.visible == true"
            title="Müşteri Bilgileri"
            :disabled="calendar.tabs.customer.disabled"
          >
            <div>
              <div>
                <b-row>
                  <b-col lg="6" sm="12">
                    <div class="form-group">
                      <label class="font-medium text-uppercase"
                        >Servis Kayıt Tarihi</label
                      >
                      <flat-pickr
                        v-model="sf.sform.sf_date"
                        class="form-control"
                        :config="{
                          enableTime: true,
                          altFormat: 'd.m.Y',
                          altInput: true,
                          dateFormat: 'Y-m-d',
                          locale: 'tr',
                        }"
                      />
                    </div>
                  </b-col>
                  <b-col lg="6" sm="12">
                    <div class="form-group">
                      <label class="font-medium text-uppercase"
                        >Servis No</label
                      >
                      <input
                        v-model="sf.sform.sf_no"
                        type="text"
                        class="form-control"
                      />
                    </div>
                  </b-col>
                </b-row>
                <div class="form-group">
                  <label class="font-medium text-uppercase">Özel Notlar</label>
                  <textarea
                    class="form-control"
                    placeholder="Bu form için özel notlardır"
                    rows="2"
                    v-model="sf.sform.sf_desc"
                  ></textarea>
                </div>
              </div>
            </div>
            <div class="mt-1">
              <h5 class="text-primary">Müşteri Bilgileri</h5>
              <div class="form-group" v-if="sf.customer == null">
                <label class="font-medium text-uppercase">Müşteri Ara</label>
                <vue-autosuggest
                  ref="autocomplete"
                  v-model="customer_search.query"
                  :limit="10"
                  :suggestions="customer_search.suggestions"
                  :input-props="customer_search.inputProps"
                  :section-configs="customer_search.sectionConfigs"
                  :render-suggestion="renderSuggestion"
                  @input="searchCustomer"
                />
              </div>
              <div v-if="sf.customer != null">
                <b-row>
                  <b-col sm="12" lg="9">
                    <div class="form-group">
                      <label class="font-medium text-uppercase">Müşteri</label>
                      <input
                        v-model="sf.customer.title"
                        type="text"
                        class="form-control"
                        readonly
                      />
                    </div>
                  </b-col>
                  <b-col sm="12" lg="3">
                    <div class="form-group">
                      <label class="font-medium text-uppercase">Kod</label>
                      <input
                        v-model="sf.customer.customer_code"
                        type="text"
                        class="form-control"
                        readonly
                      />
                    </div>
                  </b-col>
                  <b-col>
                    <div class="form-group">
                      <label class="font-medium text-uppercase"
                        >Email Adresi</label
                      >
                      <input
                        v-model="sf.customer.email_primary"
                        type="text"
                        class="form-control"
                        readonly
                      />
                    </div>
                  </b-col>
                  <b-col>
                    <div class="form-group">
                      <label class="font-medium text-uppercase">GSM</label>
                      <input
                        v-model="sf.customer.gsm_primary"
                        type="text"
                        class="form-control"
                        readonly
                      />
                    </div>
                  </b-col>
                </b-row>
                <b-form-group>
                  <button
                    v-on:click="clearSelectedCustomer()"
                    class="btn text-danger p-0 btn-sm"
                  >
                    Vazgeç
                  </button>
                </b-form-group>
              </div>
            </div>
          </b-tab>
          <b-tab
            v-if="calendar.tabs.device.visible == true"
            title="Cihaz Durumu"
            :disabled="calendar.tabs.device.disabled"
          >
            <div>
              <b-row class="mb-1">
                <b-col lg="6">
                  <div class="d-inline-block font-medium text-uppercase">
                    DURUM
                  </div>
                  <div class="d-inline-block ml-1">
                    <status-badge :status="device_status_item()"></status-badge>
                  </div>
                </b-col>
                <b-col lg="6">
                  <div class="d-inline-block font-medium text-uppercase">
                    ÇIKMASI GEREKEN TARİH :
                    {{ convertdate(sf.device.due_date) }}
                  </div>
                  <div class="d-inline-block ml-1">
                    <span
                      v-if="sf.device.is_immediate"
                      class="badge badge-danger"
                      >ACİL</span
                    >
                  </div>
                </b-col>
              </b-row>
              <b-row>
                <b-col lg="6">
                  <div class="form-group">
                    <label class="font-medium text-uppercase"
                      >Marka - Model</label
                    >
                    <input
                      :value="sf.device.device_name"
                      disabled
                      type="text"
                      class="form-control"
                    />
                  </div>
                </b-col>
                <b-col lg="6">
                  <div class="form-group">
                    <label class="font-medium text-uppercase">Barkod</label>
                    <input
                      :value="sf.device.device_barcode"
                      disabled
                      type="text"
                      class="form-control"
                    />
                  </div>
                </b-col>
              </b-row>
            </div>
            <div v-if="sf.device.device_id == 0">
              Teslimat için cihaz seçimi yapılmadı.
            </div>
          </b-tab>
        </b-tabs>
        <div slot="modal-footer">
          <button @click="calendarSave()" class="btn btn-primary">
            Kaydet
          </button>
          <router-link
            v-if="sf.sform.sf_uniq != ''"
            :to="{ name: 'service', query: { uniq: sf.sform.sf_uniq } }"
          >
            <span class="btn btn-link">Detayları Görüntüle</span>
          </router-link>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import StatusBadge from "@/views/common/StatusBadge.vue";
import { mapState, mapGetters, mapActions } from "vuex";
import { VueAutosuggest } from "vue-autosuggest";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import { tr } from "flatpickr/dist/l10n/tr.js";
import utils from "@/service/utils";
import _ from "lodash";
export default {
  components: {
    VueAutosuggest,
    vSelect,
    flatPickr,
    StatusBadge,
  },
  props: ["showmodal"],
  data() {
    return {
      customer_search: {
        datasuggest: [],
        query: "",
        suggestions: [],
        selected: null,
        inputProps: {
          id: "search_customer",
          class: "form-control",
          type: "search",
          placeholder: "gsm numarası, email, adsoyad, müşteri kodu ile ara",
        },
        sectionConfigs: {
          customers: {
            limit: 6,
            label: "",
            ulClass: "list-group list-none",
            liClass: "list-group-item",
            onSelected: (selected) => {
              this.sf.customer = selected.item;
              this.customer_search.query = selected.item.title;
              this.sf.sform.customer_id = selected.item.customer_id;
            },
          },
        },
      },
    };
  },
  computed: {
    ...mapState({
      sf: (state) => state.sf,
      global: (state) => state.global,
      calendar: (state) => state.calendar,
    }),
    districts() {
      return utils.getDistricts("34", { viewtype: "vselect" });
    },
  },
  created() {
    this.triggerChangeCalendarTask();
     this.$emit("emitmodal", false);
  },
  methods: {
    ...mapActions(["triggerChangeCalendarTask", "calendarSave","startDeliveryTask","getEvents"]),
    getDefinitionName(id) {
      return utils.getDefinitionName(id);
    },
    moment(v) {
      return utils.moment(v);
    },
    searchCustomer() {
      let keyword = this.customer_search.query;
      this.$store.dispatch("searchCustomer", { keyword, type: "member" });
    },
    clearSelectedCustomer() {
      this.customer_search.query = "";
      this.customer_search.suggestions = [];
      this.sf.sform.customer_id = 0;
      this.sf.customer = null;
    },
    device_status_item() {
      let status = { status: null, status_text: null };
      status.status = this.sf.device.device_status;
      if (this.sf.device.device_status_text != null) {
        status.status_text = this.sf.device.device_status_text;
      }
      return status;
    },
    convertdate(v) {
      let result = null;
      if (v != null) {
        result = utils.moment(v).format("DD.MM.YYYY");
      }
      return result;
    },
    startTask()
    {
      this.startDeliveryTask().then(q=>
      {
    
        if(q.success)
        {
          debugger;
          let newdata = Object.assign({},this.calendar.event);
          newdata.task        = q.data.task;
          newdata.sf_item     = q.data.sf;
          newdata.status      = q.data.task.status;
          newdata.status_text = q.data.task.status_text;
          this.calendar.event = newdata;
 
          //this.getEvents();
         /*
          this.calendar.event.task = q.data.task;
          this.calendar.event.sf_item = q.data.sf;
          this.calendar.event.status = q.data.task.status;
          this.calendar.event.status_text = q.data.task.status_text;
          */
         // console.log('calendar',this.calendar.event);
        }
     
      });
    },
    renderSuggestion(suggestion) {
      const data = suggestion.item;
      return (
        <b-list-group>
          <b-list-group-item class="p-1">
            <div class="d-flex align-items-center">
              <div class="d-flex">
                <div>
                  <b-avatar src={data.profile_image} class="mr-50"></b-avatar>
                </div>
                <div>
                  <div>
                    <span>{data.title}</span>
                  </div>
                  <div>
                    <span>{data.gsm_primary}</span>
                    <span class="ml-1">{data.email_primary}</span>
                  </div>
                </div>
              </div>
            </div>
          </b-list-group-item>
        </b-list-group>
      );
    },
    resetModal() {
      // this.global.calendar.showmodal = true;
    },
    openModal() {
      this.$refs["modalevent"].show();
    },
    hideModal() {
      this.$emit("emitmodal", false);
    },
  },
  watch: {
    "sf.customer_search_list": function (newval, oldval) {
      this.customer_search.suggestions = [];
      this.customer_search.suggestions.push({
        name: "customers",
        data: newval,
      });
    },
    showmodal: function (val, old) {
      if (val) this.openModal();
    },
  },
};
</script>

<style>
</style>