<template>
  <div>
    <div>
      <span class="text-wrap badge badge-light text-danger csfs2 d-block">
        {{ arg.event.extendedProps.task_title }}
      </span>
      <status-badge
        classlist="d-block text-uppercase text-wrap"
        :status="getStatus(arg.event.extendedProps)"
      ></status-badge>
    </div>
    <div v-if="arg.event.extendedProps.device_name != ''" class="text-sm">
      <div class="text-wrap badge text-center d-block">{{ arg.event.extendedProps.device_name }}</div>
    </div>
    <div>
      <span title="Personel" class="text-wrap badge badge-dark text-center d-block">P. {{ arg.event.extendedProps.staff_title }}</span>
    </div>
  </div>
</template>

<script>
import StatusBadge from "../common/StatusBadge.vue";
import { mapState, mapGetters, mapActions, mapMutations } from "vuex";
import utils from "@/service/utils";

export default {
  components: {
    StatusBadge,
  },
  props: ["arg"],
  computed: {
    ...mapState({
      sf: (state) => state.sf,
      calendar: (state) => state.calendar,
      global: (state) => state.global,
    }),
  },
  methods: {
    getStatus(extendedProps) {
      return {
        status: extendedProps.task_status,
        status_text: extendedProps.task_status_text,
      };
    }
  },
};
</script>
<style>
</style>