<template>
  <div>
    <div class="card card-body">
      <div class="row align-items-center">
        <div class="col-sm-2">
          <div class="form-group">
            <small class="text-uppercase font-weight-bold"
              >Başlangıç Tarihi</small
            >
            <flat-pickr
              v-model="calendar.filter.start_date"
              class="form-control"
              :config="{
                disabled: false,
                enableTime: false,
                altFormat: 'd.m.Y',
                altInput: true,
                dateFormat: 'Y-m-d',
                locale: 'tr',
              }"
            />
          </div>
        </div>
        <div class="col-sm-2">
          <div class="form-group">
            <small class="text-uppercase font-weight-bold">Bitiş Tarihi</small>
            <flat-pickr
              v-model="calendar.filter.end_date"
              class="form-control"
              :config="{
                disabled: false,
                enableTime: false,
                altFormat: 'd.m.Y',
                altInput: true,
                dateFormat: 'Y-m-d',
                locale: 'tr',
              }"
            />
          </div>
        </div>
        <div class="col-sm-4">
          <div class="form-group">
            <small class="text-uppercase font-weight-bold">Ara</small>
            <input
              type="text"
              class="form-control"
              placeholder="müşteri, serino, barkod no"
            />
          </div>
        </div>
      </div>
      <div class="row align-items-center ml-n2 mr-n2 pl-2 pr-2 pt-2" style="border-top:1px solid #e2e2e2;">
        <div class="col-sm-2" v-if="false">
          <div class="d-flex align-items-center">
            <b-form-checkbox
              @change="changeData()"
              v-model="calendar.filter.show_staff_task"
              id="cb_show_task_staff"
              switch
            />
            <label class="mb-0" for="cb_show_task_staff"
              >Personel Görevleri</label
            >
          </div>
        </div>
        <div class="col-sm-2">
          <div class="d-flex align-items-center">
            <b-form-checkbox
              @change="changeData()"
              v-model="calendar.filter.show_immediate"
              id="cb_show_task_immediate"
              switch
            />
            <label class="mb-0" for="cb_show_task_immediate"
              >Sadece Acil Cihazlar</label
            >
          </div>
        </div>
        <div class="col-sm-2">
          <div class="d-flex align-items-center">
            <b-form-checkbox
              @change="changeData()"
              v-model="calendar.filter.task_pending"
              id="cb_show_task_pending"
              switch
            />
            <label class="mb-0" for="cb_show_task_pending"
              >Bekleyen Servisler</label
            >
          </div>
        </div>
        <div class="col-sm-2">
          <div class="d-flex align-items-center">
            <b-form-checkbox
              @change="changeData()"
              v-model="calendar.filter.task_progress"
              id="cb_show_task_progress"
              switch
            />
            <label class="mb-0" for="cb_show_task_progress"
              >Devam Eden Servisler</label
            >
          </div>
        </div>
        <div class="col-sm-2">
          <div class="d-flex align-items-center">
            <b-form-checkbox
              @change="changeData()"
              v-model="calendar.filter.task_complated"
              id="cb_show_task_complated"
              switch
            />
            <label class="mb-0" for="cb_show_task_complated"
              >Tamamlanan Servisler</label
            >
          </div>
        </div>
        <div class="col-sm-2">
          <div class="form-group pt-2">
            <button v-on:click="changeData()" class="btn btn-sm btn-primary">Uygula</button>
          </div>
        </div>
      </div>
    </div>

    <div class="app-calendar overflow-hidden border">
      <div class="row no-gutters">
        <calendar-sidebar
          v-if="false"
          :is-event-handler-sidebar-active.sync="isEventHandlerSidebarActive"
        />

        <div class="col position-relative">
          <div class="card shadow-none border-0 mb-0 rounded-0">
            <div class="card-body pb-0">
              <full-calendar
                ref="refCalendar"
                :options="calendarOptions"
                class="full-calendar"
              >
                <template v-slot:eventContent="arg">
                  <div
                    class="csfs2"
                    v-if="arg.event.extendedProps.event_type == 'delivery'"
                  >
                    <event-item-delivery :arg="arg"></event-item-delivery>
                  </div>
                  <div
                    class="csfs2"
                    v-if="arg.event.extendedProps.event_type == 'device_repair'"
                  >
                    <event-item-device-task :arg="arg"></event-item-device-task>
                  </div>
                  <div
                    class="csfs2"
                    v-if="arg.event.extendedProps.event_type == 'task'"
                  >
                    <event-item-only-task :arg="arg"></event-item-only-task>
                  </div>
                  <div
                    class="csfs2"
                    v-if="arg.event.extendedProps.event_type == 'device'"
                  >
                    <event-item-device :arg="arg"></event-item-device>
                  </div>
                </template>
              </full-calendar>
            </div>
          </div>
        </div>
      </div>
      <div>
        <event-content
          @emitmodal="setShowmodal($event)"
          :showmodal="showmodal"
        ></event-content>
      </div>
    </div>
  </div>
</template>

<script>
import FullCalendar from "@fullcalendar/vue";
import fctr from "@fullcalendar/core/locales/tr";
import CalendarSidebar from "./CalendarSidebar.vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import interactionPlugin from "@fullcalendar/interaction";
import { mapState, mapGetters, mapActions, mapMutations } from "vuex";
import utils from "@/service/utils";
import EventContent from "./EventContent.vue";
import EventItemDelivery from "./EventItemDelivery.vue";
import EventItemDeviceTask from "./EventItemDeviceTask.vue";
import EventItemOnlyTask from "./EventItemOnlyTask.vue";
import EventItemDevice from "./EventItemDevice.vue";
import VDatePicker from "v-calendar/lib/components/date-picker.umd";
import flatPickr from "vue-flatpickr-component";
export default {
  components: {
    FullCalendar, // make the <FullCalendar> tag available
    CalendarSidebar,
    EventContent,
    EventItemDelivery,
    EventItemDeviceTask,
    EventItemOnlyTask,
    EventItemDevice,
    VDatePicker,
    flatPickr,
  },
  data() {
    return {
      showmodal: false,
      isCalendarOverlaySidebarActive: false,
      isEventHandlerSidebarActive: false,
      calendarOptions: {
        locale: fctr,
        plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin, listPlugin],
        initialView: "dayGridMonth",
        headerToolbar: {
          start: "sidebarToggle, prev,next, title",
          end: "dayGridMonth,timeGridWeek,timeGridDay,listMonth",
        },
        events: [],
        //dateClick: this.handleDateClick,
        eventClick: this.handleDateClick,
        editable:false
      },
    };
  },
  created() {
    let start = utils.moment().startOf('month').add('days',-10).format("YYYY-MM-DD");
    let end = utils.moment().endOf('month').add('days', 10).format("YYYY-MM-DD");
    this.calendar.filter.datepicker = {
      start,
      end,
    };
    this.calendar.filter.start_date = start;
    this.calendar.filter.end_date = end;
    this.getEvents();
    this.verticalMenu.isVerticalMenuCollapsed = true;
  },
  computed: {
    ...mapState({
      sf: (state) => state.sf,
      calendar: (state) => state.calendar,
      global: (state) => state.global,
      verticalMenu: (state) => state.verticalMenu,
    }),
  },
  methods: {
    ...mapActions(["getEvents", "triggerChangeCalendarTask"]),
    ...mapMutations(["clearSF", "clearTask", "clearDelivery", "newDevice"]),
    changeData()
    {
      this.getEvents();
    },
    handleDateClick(arg) {
 
      if (arg.dateStr != null) {
        //boş güne tıklandı

        //this.clearSF();
        //this.clearTask();
        //this.clearDelivery();
        //this.newDevice();
        //this.triggerChangeCalendarTask();
        this.sf.customer = null;
        let d = arg.dateStr + " " + utils.moment().add(30, "minute").format("HH:mm");
        this.sf.task.planned_date = d;
        this.sf.sform.sf_date = arg.dateStr;
        this.sf.sform.sf_no = utils.moment().format("DDMMYY") + "" + utils.generateUniq({ size: 4, upper: true });
      } else {
        //this.clearSF();
        //this.clearTask();
        //this.clearDelivery();
        //this.newDevice();


        let data = arg.event.extendedProps;
        this.calendar.event = data;
        this.sf.task = data.task;

        if (data.sf_item != null) {
          this.sf.sform = data.sf_item;
        }
        if (data.delivery_item != null) {
          this.sf.delivery = data.delivery_item;
        }
        if (data.device_item != null) {
          this.sf.device = data.device_item;
        }
        this.sf.customer = data.customer_item;
        this.triggerChangeCalendarTask();
      }
      this.setShowmodal(true);
    },
    setShowmodal(v) {
      this.showmodal = v;
    },
  },
  watch: {
    "calendar.events"(n, o) {
      this.calendarOptions.events = n;
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/apps/calendar.scss";

.event-category {
  display: flex !important;
  align-items: center;

  .t {
    margin-left: 3px;
    font-size: 0.7rem !important;
  }
}

.event-content {
  padding: 0.2rem 0.5rem;
  font-weight: 500;
}
.fc-event {
  white-space: normal !important;
}
.fc .fc-daygrid-event-harness .fc-event {
  font-size: 0.75rem;
}

.app-calendar .app-calendar-sidebar {
  flex-basis: 15rem;
}

.fc .fc-daygrid-event-harness .fc-event {
  display: block !important;
}

.fc .fc-daygrid-event-harness .fc-event {
  padding: 0.75rem;
}

.csmt1 {
  margin-top: 1px;
}
.csmt3 {
  margin-top: 2px;
}
.csmt3 {
  margin-top: 3px;
}
.csmt4 {
  margin-top: 4px;
}
.csmt5 {
  margin-top: 5px;
}
.csfs1 {
  font-size: 9px;
}
.csfs2 {
  font-size: 10px;
}
.csfs3 {
  font-size: 11px;
}
.csfs4 {
  font-size: 12px;
}
.csfs5 {
  font-size: 13px;
}
</style>
