<template>
  <div>
    <div>
      <div v-if="arg.event.extendedProps.sf_item!=null">
      <span class="badge badge-dark d-block">SF No :&nbsp;{{ arg.event.extendedProps.sf_item.sf_id }}</span>
        </div>
      <span class="text-wrap badge csfs2 d-block" 
        :class="{'badge-success':delivery_status == 'complated' , 'badge-light text-primary':delivery_status != 'complated'  }">
        <feather-icon v-if="delivery_status == 'complated'" icon="CheckCircleIcon" class="text-white" />
        {{ arg.event.extendedProps.task_title }}
      </span>
      <status-badge classlist="d-block text-wrap text-uppercase csmt3" :status="getStatus(arg.event.extendedProps)"></status-badge>
    </div>
    <div
      class="text-wrap csmt5"
      v-if="arg.event.extendedProps.customer_title != ''"
    >
      {{ arg.event.extendedProps.customer_title }}
    </div>
    <div
      class="text-wrap csmt5"
      v-if="arg.event.extendedProps.delivery_item != null"
    >
      <feather-icon icon="MapPinIcon" />
      {{ arg.event.extendedProps.delivery_item.district_text }}
    </div>
  </div>
</template>

<script>
import StatusBadge from "../common/StatusBadge.vue";
import { mapState, mapGetters, mapActions, mapMutations } from "vuex";
import utils from "@/service/utils";

export default {
  components: {
    StatusBadge,
  },
  props: ["arg"],
  mounted() {
    console.log('SSS',this.arg.event.extendedProps)
  },
  computed: {
    ...mapState({
      sf: (state) => state.sf,
      calendar: (state) => state.calendar,
      global: (state) => state.global,
      delivery_status() {
        return this.arg.event.extendedProps.task.status
      }
    }),
  },
  methods: {
    getStatus(extendedProps)
    {
      return {
        status: extendedProps.task_status,
        status_text: extendedProps.task_status_text,
      };
    }
  },
};
</script>

<style>
</style>