<template>
  <div>
     <v-date-picker
      full-width
      is-range
      v-model="calendar.filter.datepicker"
    />
    <div>
      <div class="d-flex border-bottom mt-1 pl-1 pr-1 pb-1">
        <b-form-checkbox
          @change="changeData()"
          v-model="calendar.filter.show_staff_task"
          id="cb_show_task_staff"
          switch
        />
        <label class="mb-0" for="cb_show_task_staff">Personel Görevleri</label>
      </div>
      <div class="d-flex border-bottom mt-1 pl-1 pr-1 pb-1">
        <b-form-checkbox
          @change="changeData()"
          v-model="calendar.filter.show_immediate"
          id="cb_show_task_immediate"
          switch
        />
        <label class="mb-0" for="cb_show_task_immediate">Sadece Acil Cihazlar</label>
      </div>
      <div class="d-flex border-bottom mt-1 pl-1 pr-1 pb-1">
        <b-form-checkbox
          @change="changeData()"
          v-model="calendar.filter.task_pending"
          id="cb_show_task_pending"
          switch
        />
        <label class="mb-0" for="cb_show_task_pending"
          >Bekleyen Servisler</label
        >
      </div>
      <div class="d-flex border-bottom mt-1 pl-1 pr-1 pb-1">
        <b-form-checkbox
          @change="changeData()"
          v-model="calendar.filter.task_progress"
          id="cb_show_task_progress"
          switch
        />
        <label class="mb-0" for="cb_show_task_progress"
          >Devam Eden Servisler</label
        >
      </div>
      <div class="d-flex border-bottom mt-1 pl-1 pr-1 pb-1">
        <b-form-checkbox
          @change="changeData()"
          v-model="calendar.filter.task_complated"
          id="cb_show_task_complated"
          switch
        />
        <label class="mb-0" for="cb_show_task_complated"
          >Tamamlanan Servisler</label
        >
      </div>
      <div v-if="false" class="d-flex border-bottom mt-1 pl-1 pr-1 pb-1">
        <b-form-checkbox
          @change="changeData()"
          v-model="calendar.filter.device_task"
          id="cb_show_task_devicetask"
          switch
        />
        <label class="mb-0" for="cb_show_task_devicetask"
          >Cihaz Görevleri</label
        >
      </div>
    </div>
  </div>
</template>

<script>
import VDatePicker from "v-calendar/lib/components/date-picker.umd";
import { mapState, mapGetters, mapActions, mapMutations } from "vuex";
export default {
  components: {
    VDatePicker,
  },
  data() {
    return {
      date: null,
    };
  },
  computed: {
    ...mapState({
      calendar: (state) => state.calendar,
      global: (state) => state.global,
    }),
  },
  methods: {
    ...mapActions(["getEvents"]),
    changeData() {
      this.getEvents();
    },
  },
};
</script>

<style>
.vc-container {
  border: none;
}
.vc-pane
{
  width: 15rem;
}
.vc-day
{
 
}
.vc-day-content
{
  font-size:var(--text-xs) !important;
 
}
.vc-weeks
{
  min-width: 100% !important;
}
</style>